import { Router } from "@solidjs/router";
import "./app.css";
import DefaultLayout from "./layouts/default";
import { routes } from "./router";

function App() {
  const myHeaders = new Headers();
  myHeaders.append("t", "123123");

  return <Router root={DefaultLayout}>{routes}</Router>;
}

export default App;
